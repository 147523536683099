export default function CircleCheckmark() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10" r="10" fill="#0A9373" />
      <path
        d="M16.5977 7.66016L15.9688 8.26172L9.32422 14.5781L8.72266 15.1523L8.12109 14.5781L5.00391 11.625L4.375 11.0234L5.57812 9.76562L6.20703 10.3672L8.72266 12.7188L14.7656 6.97656L15.3945 6.375L16.5977 7.66016Z"
        fill="#F9F3EB"
      />
    </svg>
  );
}
